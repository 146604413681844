.header {
    @apply absolute lg:fixed z-30 w-full;
}

.header .logo {
    overflow: hidden;
    text-indent: -5000px;
    /* width: 250px; */
    height: 49px;
    display: block;

    background-size: 240px 49px;
    transition: top 550ms cubic-bezier(0.215, 0.610, 0.355, 1.000),
    width 550ms cubic-bezier(0.215, 0.610, 0.355, 1.000),
    height 550ms cubic-bezier(0.215, 0.610, 0.355, 1.000),
    background-size 550ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
    @apply w-[150px] xl:w-[250px];
}



.is-sticky {
    background-color: #382e2c;
}

.is-sticky .main-menu li a, 
.is-sticky .call, 
.is-sticky .navigation-area-wrap-right ul li a {
    @apply lg:py-[27px] lg:px-0;
}

.is-sticky .logo {
    width: 106px;
    height: 84px;
    background-size: 430px 84px;
}

.is-sticky .logo img {
    @apply h-full max-w-fit;
}
