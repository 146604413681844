@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');
@import './components/index.css';
@import './template/index.css';
@import './tailwind/index.css';

body {
    font-family: 'Roboto';
}

.container {
    @apply 2xl:max-w-[1440px] px-5;
}

.btn {
    cursor: pointer;
    color: #f3efea;
    border: solid 1px #be3a34;
    background: #be3a34;
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1;
    position: relative;
    padding: 1.5em 2em;
    text-align: center;
    transition: all, 0.25s;
    @apply font-bold;
}

.header .container { @apply max-w-full; }

.casestudy-slider .swiper { @apply overflow-visible; }
.casestudy-slider .swiper .swiper-button-prev,
.casestudy-slider .swiper .swiper-button-next { @apply top-auto m-0 bottom-0 right-0 left-auto w-[60px] lg:w-[86px] h-[60px] lg:h-[86px] bg-[#be3a34] text-white; }
.casestudy-slider .swiper .swiper-button-prev { @apply right-16 lg:right-24; }
.casestudy-slider .swiper .swiper-button-next { @apply right-0; }

.casestudy-slider .swiper .swiper-button-prev::after,
.casestudy-slider .swiper .swiper-button-next::after { @apply text-2xl; }

.testimonials-slider .swiper { @apply overflow-visible pb-20 lg:pb-32; }
.testimonials-slider .swiper .swiper-slide { @apply w-full max-w-[687px]; }
.testimonials-slider .swiper .swiper-button-prev,
.testimonials-slider .swiper .swiper-button-next { @apply top-auto m-0 bottom-0 left-0 right-auto text-[#5b5957]; }
.testimonials-slider .swiper .swiper-button-prev { @apply left-0; }
.testimonials-slider .swiper .swiper-button-next { @apply left-12; }


.filter-item {}
.filter-item .check-div { @apply relative; }


.filter-item .check-div label {
    display: inline-block;
    position: relative;
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 0 0 57px;
    vertical-align: top;
    color: #f3efea;
    cursor: pointer;
    transition: color 0.3s;
    margin-right: 0;
    font-weight: 700;
    letter-spacing: 1px;
    height: 40px; }

.filter-item .check-div label::before {
width: 37px;
height: 35px;
top: 30%;
transform: translateY(-50%);
left: 0;
/*margin-top: -20px;*/
position: absolute;
cursor: pointer;
border-radius: 4px;
content: '';
border: 2px solid #fff;
transition: opacity 0.3s; }


.check-div input[type="checkbox"] {
width: 37px;
height: 35px;
top: 30%;
transform: translateY(-50%);
left: 0;
position: absolute;
cursor: pointer;
border-radius: 4px;
opacity: 0;
-webkit-appearance: none;
display: inline-block;
vertical-align: middle;
z-index: 100;
pointer-events: none;
display: none; }

.filter-item .check-div input[type="checkbox"]:checked + label {
color: #be3a34; 
}

.filter-item .check-div input[type="checkbox"]:checked + label::before {
border-color: #be3a34;
background-color: #be3a34; 
}

.filter-item .check-div input[type="checkbox"]:checked + label::after {
content: '';
border: 3px solid #fff;
border-top-width: 0;
border-right-width: 0;
transform: rotate(-45deg);
display: inline-block;
visibility: visible;
position: absolute;
width: 0.6em;
height: 0.3em;
left: 0.80em;
top: .4em; 
}

.entry p {
    @apply mb-5 text-[21px];
}

.footer-menu ul li { @apply py-1; }
.footer-menu ul li a { @apply text-white text-[#59514f] uppercase; }


.post-description p {
    @apply mb-5;
}

.why-grid-container {
    counter-reset: my-sec-counter;
    @apply relative overflow-hidden;
}
.why-grid-container::before,
.why-grid-container::after { content: ""; @apply hidden md:block absolute w-full h-[2000px]; }
.why-grid-container::before { @apply top-0 bg-[#f3efea]; }
.why-grid-container::after { @apply bottom-0 bg-[#382e2c]; }
.why-grid-container h2::before {
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter);
    @apply block bg-primary text-white w-12 md:w-16 h-12 md:h-16 text-center mb-8 leading-normal md:leading-tight;
}
.why-grid-container .why-grid { @apply relative z-10; }
.why-grid-container .why-grid:nth-of-type(2n+2) { @apply lg:flex-row-reverse; }
.why-grid-container .why-grid img { @apply mx-auto; }
.why-grid-container .why-grid:nth-last-of-type(1) h2,
.why-grid-container .why-grid:nth-last-of-type(2) h2 { @apply md:text-white; }