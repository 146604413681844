.main-menu {  @apply m-0 lg:flex; }
.main-menu > li {  @apply lg:mr-3.5 xl:mr-4 2xl:mr-10 relative; }
.main-menu > li > a { @apply text-white uppercase py-1 lg:py-12 block tracking-wide leading-8 relative text-[13px] xl:text-base; }
.main-menu .sub-menu { @apply lg:absolute lg:w-52 lg:top-full lg:-left-6 lg:-translate-x-6 z-20 lg:pt-2 hidden;}

.main-menu > li:hover .sub-menu { @apply block; }

.main-menu .sub-menu > li > a {  @apply bg-white block py-3 px-6 border-b relative shadow-md font-bold; }

.main-menu .sub-menu > li > a:hover {  @apply hover:text-primary}

.main-menu .sub-menu:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto; 
    @apply hidden lg:block;
}



.navigation-area-wrap-right ul {
    display: block;
    position: relative;
    list-style: none;
    margin: 0 0px 0 0;
    padding: 0;
}
.navigation-area-wrap-right ul {
}
.navigation-area-wrap-right ul > li {
    list-style: none;
    /* margin: 0 20px 0 0; */
    padding: 0;
    position: relative;
    transition: all 500ms ease;
    @apply m-0 lg:mr-5 lg:float-left;
}
.navigation-area-wrap-right ul > li > a {
    position: relative;
    display: block;
    color: #ffffff;
    line-height: 30px;
    opacity: 1;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1.6px;
    @apply text-[13px] xl:text-sm py-1 lg:py-[42px] text-left lg:text-center;
}

.call {
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    line-height: 30px;
    letter-spacing: 1.6px;
    position: relative;
    @apply  text-[13px] xl:text-base lg:py-[42px] lg:mr-[50px];
}

.call:after {
    content: "";
    width: 5px;
    height: 5px;
    background-color: #fff;
    position: absolute;
    right: -16%;
    top: 48%;
    border-radius: 10px;
    @apply hidden lg:block;
}

/* .mobile-menu-button span { @apply bg-primary; } */